﻿$(document).ready(function () {
    var menu$ = $("#menu-main");
    var phone$ = $("#hero-phone");

    var phone = document.getElementById("hero-phone");
    var initPhoneBottom = (phone) ? phone.getBoundingClientRect().bottom : 0; 
    
    var windows$ = $(window);

    function setMainMenuFix() {
        var scroll = windows$.scrollTop();
        var isFixed = scroll > 35;

        menu$.toggleClass("fix", isFixed);
     
        if (menu$.data("isred")) {
            menu$.toggleClass("red-bg white-text", !isFixed);
        }

        menu$.toggleClass("smaller", scroll > 60);
        $("#menu-top").toggleClass("pagehasdetachedmenu", isFixed);
    }
    
    function setPhonePosition() {
        if (!phone) {
            return;
        }

        var scroll = windows$.scrollTop();

        var maxMargin = 75;

        var margin = (scroll / initPhoneBottom) * -maxMargin;

        margin = (margin > maxMargin)
            ? maxMargin : (margin < -maxMargin)
            ? -maxMargin : margin;

        phone$.css("margin-left", -margin);
    }

    windows$.scroll(function () {
        setMainMenuFix();
        setPhonePosition();
    });

    setMainMenuFix();
   
    $("#mobilemenubtn").click(function() {
        $("#menu-mobile > .menuoptions").slideToggle();
    });
});