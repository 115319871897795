﻿function setActiveCarouselItem(carousel$, item$) {

    var items$ = carousel$.find("div[data-image]");
    items$.removeClass("active");

    var image$ = carousel$.find("img");
    image$.attr("src", item$.data("image"));
    item$.addClass("active");
}


$(document).ready(function () {
    $("div.carousel").each(function () {
        var carousel$ = $(this);

        var items$ = carousel$.find("div[data-image]");

        items$.click(function () {
            var item$ = $(this);
            setActiveCarouselItem(carousel$, item$);
            clearInterval(carousel$.timer);
        });

        carousel$.timer = setInterval(function () {
            var index = items$.index(items$.filter(".active"));
            var next = items$.length === index + 1 ? 0 : index + 1;
            var item$ = $(items$[next]);
            setActiveCarouselItem(carousel$, item$);
        }, 4500);
    });

});